import { createClassStr } from "easier-class";
import { useState } from "react";
import { toast } from "react-toastify";
import ProjectAPI from "../../includes/api/Projects";
import { preventDefault } from "../../includes/utils/htmlEvents";
import { ProjectItem } from "../../modules/shared/types/Project";
import st from "../../styles/shared";

type Props = {
  project: ProjectItem;
};
export function SectionDelete(props: Props) {
  const { project } = props;
  const [input, setInput] = useState("");

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    preventDefault(ev);

    const form = ev.currentTarget;

    if (form.confirm.value !== project.domain) {
      toast.error(`Confirm value doesn't match with domain name`);
      return;
    }

    const wait = toast.loading("Removing...");

    ProjectAPI.remove(project.id)
      .then((rs) => {
        toast.success(`Project removed`);
      })
      .catch((err) => {
        toast.error(err.toString());
      })
      .finally(() => {
        toast.dismiss(wait);
      });
  }

  function handleInputChange(ev: React.ChangeEvent<HTMLInputElement>) {
    setInput(ev.currentTarget.value);
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={createClassStr(st.box, "flex flex-col items-start p-4 gap-4")}
    >
      <label className="text-lg font-semibold">Delete Project</label>
      <p>
        <span className="text-red-600">This action can't be reverted.</span> If
        you want to delete the project, please enter <b>{project.domain}</b>{" "}
        below to confirm.
      </p>
      <input
        onChange={handleInputChange}
        name="confirm"
        className={createClassStr(st.input, "w-full w-56")}
      />
      <button disabled={input !== project.domain} className={st.btnDefault}>
        Delete
      </button>
    </form>
  );
}
