const st = {
    btnPrimary: "text-white dark:text-black bg-gradient-to-b from-orange-300 to-orange-400  hover:from-orange-400 hover:to-orange-500 duration-200 relative rounded p-3 text-sm font-bold",
    borderColor: `border-slate-300`,
    borderColorLtr: `border-slate-200`,
    flexCenter: `flex items-center justify-center`,
    btnDefault: `text-white font-semibold duration-200 text-center p-2 px-6 rounded bg-zinc-800 hover:bg-zinc-900 disabled:bg-slate-400/60 disabed:cursor-not-allowed flex flex-row items-center justify-center gap-2`,
    btnOutline: `font-semibold duration-200 text-center p-2 px-6 rounded disabled:bg-slate-400/60 disabed:cursor-not-allowed flex flex-row items-center justify-center gap-2 border`,
    bg: `bg-white`,
    bgLt: `bg-slate-100`,
    bgLtr: `bg-slate-200`,
    bgGradientPrimary: `bg-gradient-to-b from-orange-300 to-orange-400  hover:from-orange-400 hover:to-orange-500 duration-200 text-zinc-900`,
    textColorPrimary: "text-orange-500",
    box: "border rounded-lg text-sm shadow-md shadow-slate-200/70 bg-white",
    input: "duration-200 py-2 px-3 border rounded-md outline-none focus:border-slate-500"
}

export default st;