import st from "../styles/shared";

import { useState } from "react";
import { createClassStr } from "easier-class";
import { TabControlView } from "./Box/TabControlView";
import { OverviewItem } from "../modules/shared/types/Project";
import { ArrowRotateRight, ClipboardText } from "iconsax-react";

export type SectionRenderItemProps = { item: OverviewItem; index: number };

type SectionProps = {
  labelClassName?: string;
  valueClassName?: string;
  labelText: string;
  valueText: string;
  data?: OverviewItem[];
  renderItem?: (options: SectionRenderItemProps) => any;
};

type Props = {
  title?: string;
  sections: SectionProps[];
  className?: string;
  activeTab?: number;
};

export function SectionAnalyticComponent(props: Props) {
  const { title, sections, className } = props;
  const [currentTab, setTab] = useState(0);
  const [expanded, setExpand] = useState(false);
  const activeTab =
    typeof props.activeTab === "number" ? props.activeTab : currentTab;

  const {
    labelText,
    valueText,
    renderItem,
    labelClassName = "capitalize",
    valueClassName,
  } = sections[activeTab];

  const data = (sections[activeTab].data || []).sort(
    (a, b) => b.value - a.value
  );

  function _renderItem(item: OverviewItem, i: number) {
    if (!expanded && i > 4) {
      return null;
    }

    if (renderItem) {
      return renderItem({ item, index: i });
    }

    const { label, value } = item;
    return (
      <div
        key={`${title}-${label}`}
        className={createClassStr(
          "flex flex-row justify-between px-4 py-2 hover:bg-slate-100/40",
          i > 0 && "border-t",
          st.borderColorLtr
        )}
      >
        <span className={labelClassName}>{label}</span>
        <span className={valueClassName}>{value}</span>
      </div>
    );
  }

  function toggle() {
    setExpand(!expanded);
  }

  return (
    <div className={createClassStr(className || st.box, "col-span-1")}>
      {title ? (
        <div className="flex flex-row item-center justify-between p-4 border-b">
          <label className="text-lg font-semibold">{title}</label>
          <TabControlView
            tabs={sections.map((item) => item.labelText)}
            onChange={setTab}
          />
        </div>
      ) : null}

      {data ? (
        data.length ? (
          <>
            <div
              className={createClassStr(
                "border-b p-2 px-4 flex flex-row justify-between text-slate-500 font-semibold",
                st.bgLt
              )}
            >
              <span className="capitalize">{labelText}</span>
              <span className="capitalize">{valueText}</span>
            </div>
            <div className="text-slate-700">
              {data.map(_renderItem)}
              {data.length > 5 ? (
                <div className="p-4 flex flex-row justify-center border-t">
                  <button
                    className="duration-200 font-semibold hover:opacity-70"
                    onClick={toggle}
                  >
                    {expanded ? "Show less" : "Show more"}
                  </button>
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <div className="border-t px-4 py-12 flex flex-col items-center justify-center gap-6 text-slate-400">
            <span className="border-2 rounded-full p-4 border-slate-200">
              <ClipboardText size={42} />
            </span>
            <span className="text-base">No data received</span>
          </div>
        )
      ) : (
        <div className="border-t px-4 py-12 flex flex-col items-center justify-center gap-6 text-slate-400">
          <span className="rounded-full p-4">
            <ArrowRotateRight className="animate-spin" size={42} />
          </span>
          <span className="text-base">Loading</span>
        </div>
      )}
    </div>
  );
}
