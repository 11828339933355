import { createClassStr } from "easier-class";
import React from "react";
import st from "../styles/shared";

type Props = {
  title: string;
  children?: any;
  as?: string;
  className?: string;
  bodyClassName?: string;
  [name: string]: any;
};

export function Box(props: Props) {
  const {
    title,
    children,
    as = "div",
    className,
    bodyClassName,
    ...rest
  } = props;

  return React.createElement(
    as,
    { className: createClassStr(st.box, "col-span-1", className), ...rest },
    <>
      <div className="flex flex-row item-center justify-between p-4">
        <label className="text-lg font-semibold">{title}</label>
      </div>
      <div
        className={createClassStr(
          "border-t px-6 py-4 flex flex-col gap-6",
          bodyClassName
        )}
      >
        {children}
      </div>
    </>
  );
}
