import { createClassStr } from "easier-class";
import { ArrowRotateRight } from "iconsax-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../includes/conn";

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {

    auth.signOut()
    .finally(() => {
        navigate("/login")
    })

  });

  return (
    <div className={createClassStr("flex items-center justify-center")}>
      <ArrowRotateRight className="animate-spin" size={32} />
      <span>Loading...</span>
    </div>
  );
}
