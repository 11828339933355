import AppContext from "../Context";
import st from "../../styles/shared";

import { createClassStr } from "easier-class";
import { Add, ArrowDown3 } from "iconsax-react";
import { Link, NavLink, useMatch } from "react-router-dom";
import { useContext, useState } from "react";

import { ProjectItem } from "../../modules/shared/types/Project";
import { SiteIcon } from "../../components/SiteIcon";

const activeClass = "bg-slate-100 text-slate-500";

const itemClass = "duration-200 flex flex-row items-center gap-3 py-3 px-4";

export function ProjectMenuOverlayView() {
  const [visible, setVisible] = useState(false);
  const { user, projects } = useContext(AppContext);

  const pid = useMatch("/projects/:pid/:screen")?.params.pid;
  const currentProject = pid
    ? projects?.find((item) => item.id === pid)
    : undefined;

  const title = currentProject ? (
    <span className="flex flex-row items-center gap-3">
      <SiteIcon
        domain={currentProject.domain}
        name={currentProject.name}
        size={21}
      />
      <span className="text-ellipsis overflow-hidden whitespace-nowrap w-36">
        {currentProject.name}
      </span>
    </span>
  ) : (
    `(select project)`
  );

  // function handleInsert() {
  //   let projectName = prompt("Enter folder name");

  //   if (!projectName) return;

  //   fc.Project.insert({
  //     title: projectName,
  //   })
  //     .then((item) => {
  //       // @ts-ignore
  //       navigate(`/p/${item.id}`);
  //       toast.success("Folder created");
  //     })
  //     .catch((err) => toast.error(err.toString()));
  // }

  function toggleVisible() {
    setVisible(!visible);
  }

  function renderItem(item: ProjectItem, i: number) {
    return (
      <NavLink
        key={`project-item-${item.id}`}
        className={({ isActive }) =>
          createClassStr(
            itemClass,
            i !== 0 && "border-t",
            isActive || item.id === pid ? activeClass : "hover:bg-slate-100/40"
          )
        }
        onClick={toggleVisible}
        to={`/projects/${item.id}`}
      >
        <SiteIcon domain={item.domain} name={item.name} size={21} />
        <label className="font-semibold">{item.name}</label>
      </NavLink>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <div className="relative select-none">
      <label
        onClick={toggleVisible}
        className={createClassStr(
          "flex items-center justify-between gap-2 text-sm font-semibold cursor-pointer p-2 pr-4 border",
          visible ? "text-slate-900 rounded-t-md" : "rounded-md",
          "shadow-sm",
          st.borderColor,
          st.bg
        )}
      >
        {title}
        <ArrowDown3
          className={createClassStr("duration-200", visible ? "rotate-90" : "")}
          variant={visible ? "Bold" : "Outline"}
          size={14}
        />
      </label>
      <div
        className={createClassStr(
          visible ? "" : "hidden",
          "absolute top-[37px] w-full flex flex-col z-10 shadow-md shadow-slate-200",
          "border rounded-b-lg overflow-hidden",
          st.bg,
          st.borderColor
        )}
      >
        <div className={createClassStr("flex-1 flex flex-col max-h-[400px]")}>
          <div className="scrollbar overflow-y-auto">
            {(projects || []).map(renderItem)}
          </div>
          <div className="p-2 flex flex-row justify-center border-t">
            <Link
              to="/create-project"
              className={createClassStr(
                "flex-1 flex flex-row justify-center items-center gap-2 duration-200 font-semibold hover:text-slate-900 text-slate-700 border rounded-md p-2 bg-slate-100/20"
              )}
            >
              <Add size={18} />
              Create project
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
