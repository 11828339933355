import { createClassStr } from "easier-class";
import { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../components/icons/Spinner";
import { auth } from "../../includes/conn";
import st from "../../styles/shared";
import { LoginWithProvider } from "./LoginWithProvider";

export function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordLess, setPasswordLess] = useState(true);
  const navigate = useNavigate();

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    if (passwordLess) {
      return handlePasswordlessLogin();
    }

    if (email && password) {
      setLoading(true);

      const { error } = await auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        toast.error(error.message);
      } else {
        toast.success("Logged in");
        navigate("/");
      }
      setLoading(false);
    }
  };

  function togglePasswordless() {
    setPasswordLess(!passwordLess);
  }

  async function handlePasswordlessLogin() {
    setLoading(true);

    const { error } = await auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: window.location.origin,
      },
    });

    if (error) {
      toast.error(error.message);
    } else {
      toast.success(
        "We've sent an email with login password. Please check your email"
      );
    }
    setLoading(false);
  }

  return (
    <div className="flex flex-col h-full w-full items-center justify-center">
      <h1 className="text-2xl font-bold mb-6 w-72">Login</h1>
      <div className={createClassStr("border rounded-md p-6 relative", st.bg)}>
        <LoginWithProvider />

        <div className="mb-6 text-center">
          <small>or login with email</small>
        </div>

        <form className="flex flex-col gap-2 w-64" onSubmit={handleLogin}>
          <div className="flex flex-col border rounded p-2 px-3 gap-2">
            <label className="text-xs font-bold" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              className="bg-transparent outline-none"
              type="email"
              placeholder="i.e john@citizen.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {passwordLess ? (
            <small>
              Enter your email address and we’ll send a login link to your
              inbox.
            </small>
          ) : (
            <>
              <div className="flex flex-col border rounded p-2 px-3 gap-2">
                <label className="text-xs font-bold" htmlFor="password">
                  Password
                </label>
                <input
                  id="password"
                  className="bg-transparent outline-none"
                  type="password"
                  placeholder="at least 8 chars"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="flex justify-end text-xs mb-2">
                <Link to="/reset-password">Forgot password?</Link>
              </div>
            </>
          )}
          <div
            onClick={togglePasswordless}
            className={`flex flex-row items-center mt-2 border text-xs rounded p-2 cursor-pointer ${st.borderColor}`}
          >
            <input
              onChange={togglePasswordless}
              className="mr-2"
              type="checkbox"
              checked={!passwordLess}
            />{" "}
            Login with password
          </div>
          <button
            disabled={loading}
            className={`${st.btnPrimary} mt-4`}
            aria-live="polite"
          >
            {loading ? (
              <Spinner color="zinc" className="absolute right-4 top-3.5" />
            ) : null}
            {passwordLess ? "Get login link" : "Login"}
          </button>
        </form>
        <p className="mt-4 text-xs">
          Haven't got an account?{" "}
          <Link className="font-bold" to="/register">
            Register
          </Link>
        </p>
      </div>
    </div>
  );
}
