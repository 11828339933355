import st from "../styles/shared";

import { useState } from "react";
import { createClassStr } from "easier-class";

type SectionProps = {
  label: string;
  children: any;
};

type Props = {
  boxId?: string,
  title: string;
  sections: SectionProps[];
};

export function BoxTab(props: Props) {
  const { title, sections, boxId } = props;
  const [activeTab, setTab] = useState(0);
  const tab = sections[activeTab];

  function renderBtnLabel(item: SectionProps, i: number) {
    const { label } = item;

    function handleClick() {
      setTab(i);
    }

    return (
      <button
        key={`${title}-tab-${label}`}
        className={createClassStr(
          "border rounded px-2 py-1 text-xs font-semibold",
          i === activeTab
            ? "border-slate-300 shadow-sm shadow-slate-100 text-slate-800"
            : "border-transparent text-slate-400"
        )}
        onClick={handleClick}
      >
        {label}
      </button>
    );
  }

  return (
    <div id={boxId} className={createClassStr(st.box, "col-span-1")}>
      <div className="flex flex-row item-center justify-between p-4">
        <label className="text-lg font-semibold">{title}</label>
        {sections.length > 1 ? (
          <div className="flex flex-row gap-1">
            {sections.map(renderBtnLabel)}
          </div>
        ) : null}
      </div>
      <div className="border-t px-6 py-4 flex flex-col gap-6">
        {tab.children}
      </div>
    </div>
  );
}
