import st from "../../styles/shared";

import { createClassStr } from "easier-class";
import { SectionRenderItemProps } from "../../components/SectionAnalyticComponent";
import { getCountryName } from "../../includes/utils/text";

export function renderCountryItem({ item, index }: SectionRenderItemProps) {
  const { label, value } = item;
  return (
    <div
      key={`${value}-${label}`}
      className={createClassStr(
        "flex flex-row items-center justify-between px-4 py-2 hover:bg-slate-100/40",
        index > 0 && "border-t",
        st.borderColorLtr
      )}
    >
      <CountryView country={label} withFullName />
      <span>{value}</span>
    </div>
  );
}

export function CountryView(props: {
  country?: string;
  className?: string;
  withFullName?: boolean;
}) {
  const label = props.country || "(unknown)";
  const { withFullName = false } = props;
  return (
    <span
      className={createClassStr(
        props.className,
        "flex flex-row gap-3 items-center"
      )}
    >
      {label !== "(unknown)" ? (
        <>
          <img
            className="rounded-sm shadow-sm"
            height={16}
            width={23}
            title={getCountryName(label)}
            alt={getCountryName(label)}
            src={`https://raw.githubusercontent.com/madebybowtie/FlagKit/master/Assets/SVG/${label.toUpperCase()}.svg`}
          />
          {withFullName ? getCountryName(label) : null}
        </>
      ) : (
        <>
          <span className="w-[23px] h-[16px] inline-block flex items-center justify-center bg-slate-100 text-xs font-semibold rounded">
            ?
          </span>
          {withFullName ? "(unknown)" : null}
        </>
      )}
    </span>
  );
}
