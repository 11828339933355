import st from "../styles/shared";
import AppContext from "../layouts/Context";
import ProjectAPI from "../includes/api/Projects";
import Spinner from "../components/icons/Spinner";

import { toast } from "react-toastify";
import { createClassStr } from "easier-class";
import { useContext, useEffect, useRef, useState } from "react";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { ArrowLeft, ArrowRight, ClipboardText } from "iconsax-react";

import { timeAgo } from "../includes/utils/date";
import { SiteItem } from "../modules/shared/types/Site";
import { CountryView } from "./shared/renderCountryItem";
import { base64ToJson } from "../includes/utils/text";
import { isMobile } from "../includes/utils/device";

const requestLimit = 10;

export function VisitorComponent() {
  const [state, setState] = useState<{
    data: SiteItem[];
    next?: string;
    prev?: string;
  }>();
  const { projects } = useContext(AppContext);
  const match = useMatch("/projects/:pid/:screen");
  const pid = match?.params.pid;
  const project = pid ? projects?.find((item) => item.id === pid) : undefined;
  const pagingRef = useRef<string>();
  const pageCountRef = useRef(0);
  const [searchParam] = useSearchParams();
  const { data } = state || {};
  const paging = searchParam.get("paging");
  const queryRef = `${pid}-${paging}`;
  const navigate = useNavigate();

  const { page = 0 } = paging ? base64ToJson(paging) : {};

  useEffect(() => {
    if (!data || pagingRef.current !== queryRef) {
      fetchItems();
    }
  });

  function fetchItems() {
    if (project) {
      pagingRef.current = queryRef;
      ProjectAPI.getVisitor({
        id: project.id,
        paging: paging || "",
        limit: requestLimit,
      })
        .then((rs) => {
          pageCountRef.current++;
          setState(rs);
        })
        .catch((err) => {
          toast.error(err.toString());
        });
    }
  }

  function navigateNext() {
    if (state?.next) {
      navigate(`${match?.pathname}?paging=${state.next}`);
    }
  }

  function navigatePrev() {
    if (state?.prev) {
      navigate(`${match?.pathname}?paging=${state.prev}`);
    }
  }

  function renderItem(item: SiteItem, i: number) {
    const { sid } = item;
    return (
      <div
        key={`vistor-${item.id}`}
        className={createClassStr(
          "flex flex-row justify-between px-4 py-3 hover:bg-slate-100/40",
          i > 0 && "border-t",
          st.borderColorLtr
        )}
      >
        <span className="flex-1">{ isMobile ? sid.substring(0, 5) + "..." : sid}</span>
        <CountryView country={item.country} className="flex-1 justify-center" />
        <span className="flex-1 text-center capitalize">{item.device}</span>
        <span className="flex-1 text-end">{timeAgo(item.created, { format: "short" })}</span>
      </div>
    );
  }

  return (
    <div>
      <h1 className="font-black text-4xl">Visitors</h1>
      {project ? (
        <span className="text-slate-500 font-semibold mt-4 text-sm">
          {project.domain}
        </span>
      ) : null}

      <div className={createClassStr("mt-8", st.box)}>
        <div className="flex flex-row item-center justify-between p-4">
          <label className="text-lg font-semibold">Visitors</label>
        </div>
        {data ? (
          data.length ? (
            <>
              <div
                className={createClassStr(
                  "border-t border-b p-2 px-4 flex flex-row justify-between text-slate-500 font-semibold",
                  st.bgLt
                )}
              >
                <span className="flex-1">Visitor</span>
                <span className="flex-1 text-center">Country</span>
                <span className="flex-1 text-center">Device</span>
                <span className="flex-1 text-end">Last seen</span>
              </div>
              <div className="text-slate-700">{data.map(renderItem)}</div>
            </>
          ) : (
            <div className="border-t px-4 py-12 flex flex-col items-center justify-center gap-6 text-slate-400">
              <span className="border-2 rounded-full p-4 border-slate-200">
                <ClipboardText size={42} />
              </span>
              <span className="text-base">No data received</span>
            </div>
          )
        ) : (
          <div className="border-t px-4 py-12 flex flex-col items-center justify-center gap-6 text-slate-400">
            <Spinner size={8} />
            <span className="text-base">Loading</span>
          </div>
        )}
        <div className="flex flex-row gap-4 items-center justify-center py-4 px-6 border-t">
          <button
            disabled={!state?.prev}
            onClick={navigatePrev}
            className={createClassStr(st.btnDefault, "w-16")}
          >
            <ArrowLeft size={21} />
          </button>
          <span className={createClassStr(st.btnOutline)}>Page {page + 1}</span>
          <button
            disabled={!state?.next}
            onClick={navigateNext}
            className={createClassStr(st.btnDefault, "w-16")}
          >
            <ArrowRight size={21} />
          </button>
        </div>
      </div>
    </div>
  );
}
