import { createClassStr } from "easier-class";
import { createRef, useEffect, useRef } from "react";
import { getCountryISO3 } from "../includes/utils/countryISOCodes";
import { OverviewItem } from "../modules/shared/types/Project";
import st from "../styles/shared";
import Spinner from "./icons/Spinner";

const Datamap = require("datamaps");

type Props = {
  items?: OverviewItem[];
  projectId?: string;
  className?: string;
};

export default function DatamapView(props: Props) {
  let { items, projectId, className } = props;
  const mapRef = createRef<HTMLDivElement>();
  const pidRef = useRef("");

  useEffect(() => {
    if (items) {
      if (mapRef.current) {
        mapRef.current.innerHTML = "";
      }

      pidRef.current = projectId || "";
      let data = {};
      let max = Math.max(...items.map(item => item.value))

      let fills: any = {
        defaultFill: "#e4e9ee",
      };

      items
        .sort((a, b) => b.value - a.value)
        .forEach((item) => {
          let percentage = (item.value * 100) / max;

          let key = getCountryISO3(item.label) as string;
          // @ts-ignore
          data[key] = {
            fillKey: key,
            value: item.value,
          };

          // @ts-ignore
          fills[key] = `rgb(25 103 210 / ${20 + (percentage * 80) / 100}%)`;
        });
      // @ts-ignore
      new Datamap({
        element: mapRef.current,
        geographyConfig: {
          popupTemplate: function (geography: any, data: any) {
            return `<div class="flex flex-col ${st.box} py-2 px-4">
            <label class="font-bold">${geography.properties.name}</label>
            <label class="text-slate-600 text-xs">Pageviews: ${data.value}
            </div>`;
          },
          popupOnHover: true,
          highlightFillColor: `rgb(25 103 210)`,
          highlightBorderOpacity: 0
        },
        fills,
        data,
      });
    }
  }, [pidRef, mapRef, items, projectId]);

  return (
    <div
      className={createClassStr(
        "relative h-[200px] text-[#1a73e8]",
        className
      )}
      ref={mapRef}
    >
      {!items ? (
        <div className="flex-1 flex items-center justify-center h-full gap-4 text-slate-600">
          <Spinner />
          <span>Loading...</span>
        </div>
      ) : null}
    </div>
  );
}
