export function Icon(props?: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="150"
      viewBox="0 0 150 150"
      {...props}
    >
      <defs>
        <radialGradient
          id="radial-gradient"
          cx="0.954"
          cy="1.11"
          r="0.824"
          gradientTransform="translate(0.035 -0.032) rotate(3.854)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#4242f4" />
          <stop offset="1" stopColor="#9d74f8" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="radial-gradient-2"
          cx="0.283"
          cy="-0.423"
          r="1.065"
          gradientTransform="translate(-0.108 -0.058) rotate(20.208)"
        />
        <radialGradient
          id="radial-gradient-3"
          cx="-0.118"
          cy="1"
          r="0.824"
          gradientTransform="translate(0.035 -0.032) rotate(3.854)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#cb42f4" />
          <stop offset="1" stopColor="#9d74f8" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="radial-gradient-4"
          cx="0.061"
          cy="1.155"
          r="0.355"
          gradientTransform="matrix(0.9, -0.437, 0.437, 0.9, -0.498, 0.142)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#f44286" />
          <stop offset="1" stopColor="#f874ca" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="radial-gradient-5"
          cx="0.297"
          cy="-0.601"
          r="0.833"
          gradientTransform="translate(0.08 0.118) rotate(-20.367)"
        />
      </defs>
      <g id="icon" transform="translate(-25 -25)">
        <path
          id="Path_22"
          data-name="Path 22"
          d="M36,0h78a36,36,0,0,1,36,36v78a36,36,0,0,1-36,36H36A36,36,0,0,1,0,114V36A36,36,0,0,1,36,0Z"
          transform="translate(25 25)"
        />
        <path
          id="Path_23"
          data-name="Path 23"
          d="M36,0h78a36,36,0,0,1,36,36v78a36,36,0,0,1-36,36H36A36,36,0,0,1,0,114V36A36,36,0,0,1,36,0Z"
          transform="translate(25 25)"
          opacity="0.855"
          fill="url(#radial-gradient)"
          style={{ mixBlendMode: "lighten", isolation: "isolate" }}
        />
        <path
          id="Path_24"
          data-name="Path 24"
          d="M36,0h78a36,36,0,0,1,36,36v78a36,36,0,0,1-36,36H36A36,36,0,0,1,0,114V36A36,36,0,0,1,36,0Z"
          transform="translate(25 25)"
          opacity="0.855"
          fill="url(#radial-gradient-2)"
          style={{ mixBlendMode: "lighten", isolation: "isolate" }}
        />
        <path
          id="Path_25"
          data-name="Path 25"
          d="M36,0h78a36,36,0,0,1,36,36v78a36,36,0,0,1-36,36H36A36,36,0,0,1,0,114V36A36,36,0,0,1,36,0Z"
          transform="translate(25 25)"
          opacity="0.855"
          fill="url(#radial-gradient-3)"
          style={{ mixBlendMode: "lighten", isolation: "isolate" }}
        />
        <path
          id="Path_26"
          data-name="Path 26"
          d="M36,0h78a36,36,0,0,1,36,36v78a36,36,0,0,1-36,36H36A36,36,0,0,1,0,114V36A36,36,0,0,1,36,0Z"
          transform="translate(25 25)"
          opacity="0.855"
          fill="url(#radial-gradient-4)"
          style={{ mixBlendMode: "lighten", isolation: "isolate" }}
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M36,0h78a36,36,0,0,1,36,36v78a36,36,0,0,1-36,36H36A36,36,0,0,1,0,114V36A36,36,0,0,1,36,0Z"
          transform="translate(25 25)"
          opacity="0.855"
          fill="url(#radial-gradient-5)"
          style={{ mixBlendMode: "lighten", isolation: "isolate" }}
        />
        <path
          id="Path_26-2"
          data-name="Path 26"
          d="M58.73,84.344A15.987,15.987,0,0,1,42.761,68.375V20.469a15.969,15.969,0,1,1,31.938,0V68.375A15.987,15.987,0,0,1,58.73,84.344Zm0-69.2a5.329,5.329,0,0,0-5.323,5.323V68.375a5.323,5.323,0,1,0,10.646,0V20.469A5.329,5.329,0,0,0,58.73,15.146Zm-37.261,69.2A15.987,15.987,0,0,1,5.5,68.375V49.745a15.969,15.969,0,1,1,31.938,0v18.63A15.987,15.987,0,0,1,21.469,84.344Zm0-39.922a5.329,5.329,0,0,0-5.323,5.323v18.63a5.323,5.323,0,1,0,10.646,0V49.745A5.329,5.329,0,0,0,21.469,44.422Z"
          transform="translate(59.901 55.578)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
