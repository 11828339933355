import { Menu } from "./Menu";
import { LayoutProps } from "../types";
import { createClassStr } from "easier-class";
import { isIPhone, isMobile } from "../../includes/utils/device";
import { useContext, useEffect } from "react";
import { useMatch } from "react-router-dom";
import AppContext from "../Context";

let prevRoute = "";

export default function AppLayout(props: LayoutProps) {
  const ctx = useContext(AppContext);
  const { children } = props;
  const match = useMatch("*");

  useEffect(() => {
    if (isMobile && prevRoute !== match?.pathname) {
        prevRoute = match?.pathname || "";
      if (ctx.menuOpen) {
        ctx.updateState({ menuOpen: false })
      }
    }
  })

  return (
    <div
      className={createClassStr(
        // `flex-1 flex flex-col sm:flex-row h-screen w-screen overflow-hidden bg-slate-100 text-slate-900 justify-center`,
        `flex-1 flex flex-col sm:flex-row max-w-screen justify-center`,
        isIPhone && "h-screen-webkit",
        isMobile && "mobile"
      )}
    >
      <div className="relative flex flex-row w-full max-w-[1180px] flex-1 scrollbar">
        {/* <MenuUtilsView /> */}
        <Menu />
        <div className="flex-1 flex flex-col overflow-y-auto scrollbar py-6 px-4 sm:px-8">
          {children}
        </div>
      </div>
    </div>
  );
}
