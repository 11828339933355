import st from "../styles/shared";
import AppContext from "./Context";

import { LayoutProps } from "./types";
import { createClassStr } from "easier-class";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type AccountLayoutProps = LayoutProps & {
  navigateLoggedInUser?: boolean;
};

export default function AccountLayout(props: AccountLayoutProps) {
  const { navigateLoggedInUser = true } = props;
  const { user, projects } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (navigateLoggedInUser && user && projects) {
      projects.length
        ? navigate(`/projects`)
        : navigate(`/create-project`);
    }
  });

  return (
    <div
      className={createClassStr(
        "flex-1 flex flex-col items-center justify-center min-w-screen min-h-screen overflow-y-auto scrollbar p-6",
        st.bgLt
      )}
    >
      {props.children}
    </div>
  );
}
