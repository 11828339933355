export function textTrim(text: string, maxSize: number) {

  let visibleStr: string = text || '';

  if (visibleStr && maxSize < visibleStr.length) {
    visibleStr = visibleStr.substring(0, maxSize) + '...';
  }

  return visibleStr
}

export function getFlagEmoji(countryCode: string) {

  if (countryCode !== `(unknown)`) {

    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
  }
  return "";
}

const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
export function getCountryName(countryCode: string) {

  if (countryCode !== `(unknown)`) {
    return regionNames.of(countryCode);
  }

  return countryCode;
}

export function jsonToBase64(data: any) {
  let text = JSON.stringify(data);
  return btoa(text)
}

export function base64ToJson(input: string) {
  let text =atob(input);
  return JSON.parse(text);
}