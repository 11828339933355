import copy from "copy-to-clipboard";

import { createRef, useState } from "react";
import { Copy, TickCircle } from "iconsax-react";
import { createClassStr } from "easier-class";

type Props = {
  content?: string;
  label?: string;
  children?: any;
  className?: string;
  getCopyingContent?: (input: string) => string;
};

const btnClass =
  "duration-200 text-slate-700 font-semibold text-[11px] px-2 rounded";

export function CodeView(props: Props) {
  const { content, label, className, children } = props;
  const codeRef = createRef<HTMLElement>();
  const [copied, setState] = useState(false);

  function handleCopy() {
    if (content) {
      let copyingContent = props.getCopyingContent
        ? props.getCopyingContent(content)
        : content;
      setState(true);
      copy(copyingContent);

      setTimeout(() => setState(false), 5000);
    }
  }

  return (
    <div
      className={createClassStr(
        "rounded-md border border-slate-300/80 bg-slate-100 text-slate-900 gap-2 relative flex flex-row items-start",
        className,
        label || content ? "p-2" : "p-4",
        label ? "" : "pl-3"
      )}
    >
      {label ? (
        <label className={createClassStr(btnClass, "py-[2px] bg-slate-300")}>
          {label}
        </label>
      ) : null}
      {children || (
        <code
          className={createClassStr("flex items-center flex-1 pt-[3px]")}
          ref={codeRef}
        >
          {content}
        </code>
      )}
      {content ? (
        <button
          onClick={handleCopy}
          className={createClassStr(
            btnClass,
            "hover:border-slate-400 text-sm py-1 bg-white border border-slate-300"
          )}
        >
          {copied ? (
            <TickCircle className="text-green-600" strokeWidth={2} size={14} />
          ) : (
            <Copy strokeWidth={2} size={14} />
          )}
        </button>
      ) : null}
    </div>
  );
}
