import { r } from "../request"
import { ProjectItem } from "../../modules/shared/types/Project";
import EventEmitter from "events";

const listener = new EventEmitter();

type GetProjectOverviewProps = {
    id: string,
    group?: string,
    start?: number,
    end?: number
}

function getOverview(props: GetProjectOverviewProps) {
    const { id, group = "day", start, end } = props;
    return r<any>(`sites/${id}/overview`, {
        query: { group, start, end }
    })
}

function listProjectsByUID(uid: string) {
    return r<ProjectItem[]>(`users/${uid}/projects`)
}

function getVisitor(props: { id: string, paging?: string, limit: number }) {
    const { id, paging, limit = 10 } = props;
    return r<any>(`sites/${id}/visitors`, {
        query: { paging, limit }
    })
}

async function create(props: { domain: string, name: string, uid: string }) {
    const { domain, name, uid } = props;
    const rs = await r<ProjectItem>(`projects`, {
        method: "post",
        data: { domain, name, uid }
    })

    if (rs.data) {
        listener.emit("change", { type: "insert", payload: { item: rs.data } });
    }

    return rs;
}

async function remove(id: string) {

    const rs = await r<{}>(`projects/${id}`, {
        method: "delete"
    })

    if (rs.data) {
        listener.emit("change", { type: "remove", payload: { item: { id } } });
    }

    return rs;
}

async function update(id: string, changes: any) {

    const rs = await r<{}>(`projects/${id}`, {
        method: "patch",
        data: changes
    })

    if (rs.data) {
        listener.emit("change", { type: "update", payload: { item: { id, ...changes } } });
    }

    return rs;
}

const ProjectAPI = {
    getOverview,
    listProjectsByUID,
    getVisitor,
    create,
    remove,
    update,

    listener
}

export default ProjectAPI;