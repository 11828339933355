import st from "../../styles/shared";
import AppContext from "../../layouts/Context";
import Spinner from "../../components/icons/Spinner";

import { useContext } from "react";
import { toast } from "react-toastify";
import { Logout } from "iconsax-react";
import { Box } from "../../components/Box";
import { auth } from "../../includes/conn";
import { Input } from "../../components/Input";
import { useNavigate } from "react-router-dom";

export function AccountSettingComponent() {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();

  async function handleLogout() {
    await auth.signOut();
    toast.info("Logged out");
    navigate("/login");
  }

  return (
    <div className="max-w-3xl">
      <h1 className="font-black text-4xl">Settings</h1>

      <div className="flex flex-col gap-4 mt-8">
        {user ? (
          <Box bodyClassName="items-start" title="Account info">
            <Input
              label="Email"
              defaultValue={user.email}
              disabled={!!user.email}
            />

            <button className={st.btnDefault} onClick={handleLogout}>
              Logout <Logout size={16} />
            </button>
          </Box>
        ) : (
          <div className="flex-1 flex items-center justify-center h-full gap-4 text-slate-600">
            <Spinner />
            <span>Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
}
