export function Twitter(props?: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36.003"
      height="29"
      viewBox="0 0 36.003 29"
      {...props}
    >
      <path
        d="M42,12.429a14.978,14.978,0,0,1-4.247,1.162A7.379,7.379,0,0,0,41,9.533a14.829,14.829,0,0,1-4.693,1.776A7.367,7.367,0,0,0,23.732,17.99,21.05,21.05,0,0,1,8.508,10.336a7.231,7.231,0,0,0-1,3.686,7.3,7.3,0,0,0,3.285,6.1A7.52,7.52,0,0,1,7.444,19.2v.086a7.343,7.343,0,0,0,5.923,7.181,7.346,7.346,0,0,1-1.941.263,7.848,7.848,0,0,1-1.392-.135,7.4,7.4,0,0,0,6.9,5.086,14.925,14.925,0,0,1-9.174,3.134A14.611,14.611,0,0,1,6,34.715,21.109,21.109,0,0,0,17.321,38c13.585,0,21.017-11.156,21.017-20.834,0-.317-.01-.633-.025-.945A14.532,14.532,0,0,0,42,12.429"
        transform="translate(-5.997 -9)"
        fill="#fff"
      />
    </svg>
  );
}
