import AppContext from "../layouts/Context";

import { useMatch } from "react-router-dom";
import { useContext } from "react";
import { SectionDelete } from "./settings/DeleteSection";
import { SectionUpdate } from "./settings/UpdateSection";
import { BoxTab } from "../components/BoxTab";
import { CodeView } from "../components/CodeView";

export function SettingComponent() {
  const { projects } = useContext(AppContext);

  const match = useMatch("/projects/:pid/:screen");
  const project = match?.params.pid
    ? projects?.find((item) => item.id === match.params.pid)
    : undefined;

  return (
    <div className="max-w-3xl">
      <h1 className="font-black text-4xl">Settings</h1>
      {project ? (
        <>
          <span className="text-slate-500 font-semibold mt-4 text-sm">
            {project.domain}
          </span>
          <div className="flex flex-col gap-4 mt-8">
            <SectionUpdate project={project} />
            <BoxTab
              boxId="setup"
              title="Integrate Gum Analytics"
              sections={[
                {
                  label: "html",
                  children: (
                    <>
                      <p>
                        Include the following code right before the{" "}
                        <code className="font-bold">{`</head>`}</code> tag:
                      </p>
                      <CodeView
                        content={`<script async data-id="${project.id}" src="https://cdn.gumanalytics.com/gum.js"></script>`}
                      />
                    </>
                  ),
                },
                {
                  label: "npm/yarn",
                  children: (
                    <>
                      <p>
                        For NPM or Yarn to install{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://npmjs.com/package/gumanalytics"
                        >
                          gumanalytics
                        </a>{" "}
                        package using the following command:
                      </p>
                      <CodeView
                        getCopyingContent={(input) => input.substring(2)}
                        label="NPM"
                        content={`$ npm install gumanalytics --save`}
                      />
                      <CodeView
                        getCopyingContent={(input) => input.substring(2)}
                        label="Yarn"
                        content={`$ yarn add gumanalytics`}
                      />
                      {/* <span className="p-4 rounded-md bg-slate-800 text-white">
                        {`$ npm install gumanalytics --save`}
                        <br />
                        <br />
                        <span className="opacity-40">{`// or using yarn`}</span>
                        <br />
                        {`$ yarn add gumanalytics`}
                      </span> */}
                      <p>
                        Then configure the package as soon as the script load
                      </p>
                      <CodeView>
                        <div>
                          {`const gumanalytics = require("gumanalytics");`}
                          <br />
                          <br />
                          <span className="opacity-40">{`// or with ES6 style`}</span>
                          <br />
                          {`import gumanalytics from "gumanalytics";`}
                          <br />
                          <br />
                          {`gumanalytics.config({ projectId: "${project.id}" })`}
                        </div>
                      </CodeView>
                    </>
                  ),
                },

                {
                  label: "pixel",
                  children: (
                    <>
                      <p>
                        Gum Pixels is a technique of embedding an image for
                        analytics.
                      </p>
                      <p>
                        Use one of the following image URLs to embed to your
                        email, open-source github readme, mobile app, your
                        codepen or anywhere that allow embedding images.{" "}
                        <i>Use PNG when you don't know which one to use</i>
                      </p>

                      <CodeView
                        label="PNG"
                        content={`https://lsn.gumanalytics.com/images/${project.id}/gp.png`}
                      />

                      <CodeView
                        label="1x1 PNG"
                        content={`https://lsn.gumanalytics.com/images/${project.id}/pixels.png`}
                      />

                      <CodeView
                        label="SVG"
                        content={`https://lsn.gumanalytics.com/images/${project.id}/gp.svg`}
                      />

                      <span>
                        Example of embedding PNG image with HTML{" "}
                        <code className="font-bold">img</code> tag:
                      </span>

                      <CodeView
                        content={`<img alt="Gum Pixels" src="https://lsn.gumanalytics.com/images/${project.id}/gp.png" width="33" height="10" />`}
                      />
                      <div className="flex flex-row items-center gap-2">
                        Example of embedding Gum Pixels image -&gt; 
                        <span className="border inline-block rounded shadow shadow-slate-200/60 p-2">
                          <img
                            alt="Gum Pixels"
                            src={`${window.location.origin}/gp.svg`}
                            width="33"
                            height="10"
                          />
                        </span>
                      </div>
                    </>
                  ),
                },
              ]}
            />
            <SectionDelete project={project} />
          </div>
        </>
      ) : null}
    </div>
  );
}
