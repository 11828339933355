import { toast } from "react-toastify";
import { ArrowLeft, Refresh } from "iconsax-react";
import { useContext, useState } from "react";
import { createClassStr } from "easier-class";

import AppContext from "../../layouts/Context";
import ProjectAPI from "../../includes/api/Projects";

import st from "../../styles/shared";
import { Link, useNavigate } from "react-router-dom";
import { preventDefault } from "../../includes/utils/htmlEvents";

export default function CreateProject() {
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    preventDefault(ev);

    const form = ev.currentTarget;
    const domain = form.domain.value;
    const name = form.sitename.value;

    if (!domain) {
      toast.error("Missing domain name");
      return;
    }

    if (!user) {
      toast.error("You must login to create a project");
      return;
    }

    setLoading(true);

    ProjectAPI.create({
      uid: user.id,
      domain,
      name: name || domain,
    })
      .then((rs) => {
        toast.success("Project created");
        navigate(`/projects/${rs.data.id}/overview`);
      })
      .catch((err) => {
        toast.error(err.toString());
      })
      .finally(() => {
        setLoading(false)
      });
  }

  return (
    <div className={createClassStr("flex flex-col h-screen max-w-full w-[460px]")}>
      <Link className={createClassStr(st.btnDefault, "fixed top-4 left-4 rounded-full text-sm")} to="/projects">
        <ArrowLeft size={16} /> Your Projects
      </Link>
      <h1 className="font-extrabold text-4xl mb-6 mt-20">New project</h1>

      <form
        className={createClassStr("flex flex-col p-6 gap-4", st.box)}
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col border rounded p-2 px-3 gap-2 bg-slate-100/60">
          <label className="text-xs font-bold" htmlFor="email">
            Site name
          </label>
          <input
            name="sitename"
            className="bg-transparent outline-none"
            placeholder="What's your site call? i.e John Citezen's site"
          />
        </div>
        <div className="flex flex-col border rounded p-2 px-3 gap-2 bg-slate-100/60">
          <label className="text-xs font-bold" htmlFor="email">
            Domain
          </label>
          <input
            name="domain"
            className="bg-transparent outline-none"
            placeholder="What's it domain name? i.e johncitizen.com"
            required
          />
        </div>
        <button className={`${st.btnDefault} mt-4 flex-1`} aria-live="polite">
          {loading ? (
            <Refresh
              size={16}
              className="animate-spin absolute right-4 top-3.5"
            />
          ) : null}
          Create
        </button>
      </form>
    </div>
  );
}
