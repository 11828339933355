import AppContext from "../Context";
import st from "../../styles/shared";

import { useContext } from "react";
import { createClassStr } from "easier-class";
import { NavLink, useMatch } from "react-router-dom";
import { Activity, HambergerMenu, Setting3, UserSquare } from "iconsax-react";

import { MenuFooter } from "./MenuFooter";
import { Icon } from "../../components/icons/Icon";
import { isMobile } from "../../includes/utils/device";
import { ProjectMenuOverlayView } from "./ProjectMenuOverlayView";
import Spinner from "../../components/icons/Spinner";

const iconSize = 18;

export function Menu() {
  const { user, projects, menuOpen, updateState } = useContext(AppContext);
  const pid = useMatch("/projects/:pid/:screen")?.params.pid;
  const project = pid ? projects?.find((item) => item.id === pid) : undefined;

  const iconClass = createClassStr("opacity-70");

  function toggleMenu() {
    updateState({ menuOpen: !menuOpen });
  }

  function linkClassName({ isActive }: any) {
    return createClassStr(
      "duration-200 rounded-md py-2 hover:px-2 flex flex-row gap-3 items-center text-[15px] font-semibold",
      isActive
        ? `text-slate-900 px-2 ${st.bgLtr}`
        : "text-slate-500 hover:text-slate-700"
    );
  }

  return (
    <>
      {isMobile ? (
        <button
          className={createClassStr(st.box, "fixed top-4 right-4 p-2")}
          onClick={toggleMenu}
        >
          <HambergerMenu size={21} />
        </button>
      ) : null}
      <div
        className={createClassStr(
          "flex flex-row z-20 text-sm h-screen h-screen-webkit",
          isMobile ? "fixed" : "sticky top-0"
        )}
      >
        <div
          className={createClassStr(
            "flex-1 overflow-hidden duration-200",
            isMobile
              ? "fixed transition-[left] bg-white shadow-lg"
              : "relative transition-[width]",
            isMobile ? (menuOpen ? "left-0" : "-left-64") : "",
            menuOpen || isMobile ? "w-screen w-64" : "w-0"
          )}
        >
          <div className="flex flex-col h-screen w-[255px] relative p-4 gap-4">
            <div className="flex flex-row items-center gap-4 my-2">
              <Icon width={38} height={38} />
              <span className="text-xl font-bold">Gum Analytics</span>
            </div>
            <ProjectMenuOverlayView />
            {project ? (
              <div className="flex flex-col gap-2">
                <NavLink
                  className={linkClassName}
                  to={`/projects/${project.id}/overview`}
                >
                  <Activity size={iconSize} className={iconClass} />
                  Overview
                </NavLink>
                <NavLink
                  className={linkClassName}
                  to={`/projects/${project.id}/visitors`}
                >
                  <UserSquare size={iconSize} className={iconClass} />
                  Visitors
                </NavLink>
                <NavLink
                  className={linkClassName}
                  to={`/projects/${project.id}/settings`}
                >
                  <Setting3 size={iconSize} className={iconClass} />
                  Settings
                </NavLink>
              </div>
            ) : user ? null : (
              <div>
                <span className="flex flex-row items-center gap-2">
                  <Spinner />
                  <i>Loading...</i>
                </span>
              </div>
            )}
            <div className="flex-1" />
            <MenuFooter />
          </div>
        </div>
      </div>
    </>
  );
}
