export function getFormattedDate(date: Date, prefomattedDate: string | boolean = false, hideYear = false) {
    // const day = date.getDate();
    // const month = MONTH_NAMES[date.getMonth()];
    // const year = date.getFullYear();
    // const hours = date.getHours();
    let minutes: any = date.getMinutes();

    if (minutes < 10) {
        // Adding leading zero to minutes
        minutes = `0${minutes}`;
    }

    const time = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    })

    if (prefomattedDate) {
        // Today at 10:20
        // Yesterday at 10:20
        return `${prefomattedDate} at ${time}`;
    }

    if (hideYear) {
        // 10. January at 10:20
        return `${date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short'
        })} at ${time}`;
    }

    // 10. January 2017. at 10:20
    return `${date.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    })} at ${time}`;
}

/**
 * Return ago time in hours, days, ...
 * @param dateParam 
 */
export function timeAgo(dateParam: any, formatOptions?: { format?: "default" | "short" }) {

    let { format = "default"} = formatOptions || {};

    if (!dateParam) {
        return null;
    }
    let text = textFormat[format];

    const date: Date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();

    const yesterday = new Date(today.getTime() - DAY_IN_MS);
    const seconds = Math.round((today.getTime() - date.getTime()) / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);
    const months = Math.round(days / 30);
    const isYesterday = yesterday.toDateString() === date.toDateString();


    if (seconds < 5) {
        return 'now';
    } else if (seconds < 60) {
        return `${seconds}${text.ss} ago`;
    } else if (seconds < 90) {
        return `about a${text.m} ago`;
    } else if (minutes < 60) {
        return `${minutes}${text.ms} ago`;
    } else if (hours < 24) {
        return `${hours}${text.hs} ago`;
    } else if (isYesterday) {
        return 'Yesterday'; // Yesterday at 10:20
    } else if (days < 30) {
        return `${days}${text.ds} ago`;
    } else if (months < 12) {
        return `${months}${text.mths} ago`;
    }

    return date.toLocaleDateString('en-US', options);

}

let textFormat = {
    default: {
        ss: ` seconds`,
        m: ' minute',
        ms: ` minutes`,
        hs: ` hours`,
        ds: ` days`,
        mths: ` months`
    },
    short: {
        ss:`s`,
        m: `m`,
        ms: `m`,
        hs: `h`,
        ds: `d`,
        mths: `mths`
    }
}


// --- Main function
export function ago(dateParam: any, formatOptions?: Intl.DateTimeFormatOptions) {
    if (!dateParam) {
        return null;
    }

    const date: Date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today.getTime() - DAY_IN_MS);
    const seconds = Math.round((today.getTime() - date.getTime()) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();


    if (seconds < 5) {
        return 'now';
    } else if (seconds < 60) {
        return `${seconds} seconds ago`;
    } else if (seconds < 90) {
        return 'about a minute ago';
    } else if (minutes < 60) {
        return `${minutes} minutes ago`;
    } else if (isToday) {
        return getFormattedDate(date, 'Today'); // Today at 10:20
    } else if (isYesterday) {
        return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
    } else if (isThisYear) {
        return getFormattedDate(date, false, true); // 10. January at 10:20
    }

    return date.toLocaleDateString('en-US', formatOptions || options);
}


const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
};

export function formatDateStr(input: Date, options?: {}) {

    return input.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    })
    // return `${input.getFullYear()}-${input.getMonth()}-${input.getDay()} ${input.getHours()}:${input.getMinutes()}`
}