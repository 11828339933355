import React from "react";
import goPromise from "go-promise";
import ProjectAPI from "../includes/api/Projects";

import { toast } from "react-toastify";
import { auth } from "../includes/conn";
import { User } from "@supabase/supabase-js";
import { isMobile } from "../includes/utils/device";
import { ProjectItem } from "../modules/shared/types/Project";
import { ListenerChangeProps } from "../includes/storage/Project";

type AppTheme = "light" | "dark" | "system";

export type AppContextProps = AppState & {
  updateState: (changes: Partial<AppState>) => void;
};

const defaultCtx: AppContextProps = {
  theme: "light",
  menuOpen: true,
  updateState(changes) {},
};

const AppContext = React.createContext<AppContextProps>(defaultCtx);
type AppContextContainerProps = { children: any };

type AppState = {
  theme: AppTheme;
  menuOpen?: boolean;
  user?: User | null;
  projects?: ProjectItem[];
};

export class AppContextContainer extends React.Component<AppContextContainerProps> {
  authSubscription?: any;
  state: AppState = {
    menuOpen: !isMobile,
    theme: "dark",
  };

  componentDidMount() {
    ProjectAPI.listener.on("change", this.handleProjectChange);

    if (typeof this.state.user === "undefined") {
      auth
        .getUser()
        .then(({ data }) => {
          this.fetchData();
        })
        .catch((err) => toast.error(err.toString()));
    }

    this.authSubscription = auth.onAuthStateChange((_, session) => {
      if (session?.user && !this.state.user) {
        this.setState({ user: session.user });
      } else if (!session?.user && this.state.user) {
        this.setState({ user: null });
      }
    }).data.subscription;
  }

  componentWillUnmount() {
    this.authSubscription?.unsubscribe();
    ProjectAPI.listener.removeListener("change", this.handleProjectChange);
  }

  handleProjectChange = ({ type, payload }: ListenerChangeProps) => {
    let nprojects = Array.from(this.state?.projects || []);
    const index = nprojects.findIndex((item) => item.id === payload.item.id);
    switch (type) {
      case "insert":
        nprojects.push(payload.item as any);
        break;
      case "remove":
        nprojects.splice(index, 1);
        break;
    }
    this.setState({ projects: nprojects });
  };

  fetchData = async () => {
    const [err, userRes] = await goPromise(auth.getSession());

    if (err || !userRes) {
      return Promise.reject(err || "Unable to get user");
    }
    const user = userRes.data.session?.user || null;
    let projects: ProjectItem[] = [];

    if (user) {
      const projectRes = await ProjectAPI.listProjectsByUID(user.id);
      projects = projectRes.data;
    }

    this.setState({ user, projects });
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          updateState: this.setState.bind(this),
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppContext;
