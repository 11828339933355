import { createRequest, RequestOptions, RequestResult } from "./request";

type Props = {
    projectId: string,
    endpoint?: string,
}

export default class GumAnalytics {

    private r: <T = any>(path: string, options?: RequestOptions) => Promise<RequestResult<T>>;
    private pid: string;
    private endpoint: string;
    private uid?: string;

    constructor(props: Props) {
        this.pid = props.projectId;
        this.endpoint = props.endpoint || "https://lsn.gumanalytics.com";
        this.r = createRequest({ endpoint: this.endpoint });
        this.init();
    }

    private log = (options?: { type?: number }) => {
        if (this.pid) {
            const { type = 1 } = options || {};
            let referer = document.referrer ? new URL(document.referrer).host : "";
            let data: any = { pathname: window.location.pathname, type };

            if (this.uid) {
                data.uid = this.uid
            }

            let headers: HeadersInit = {
                'Referer': referer
            };

            this.r(`sites/${this.pid}`, { method: "post", data, headers });
        }
    }

    private init = async () => {
        const log = this.log;

        log({ type: 0 });

        (function (history) {
            var pushState = history.pushState;
            history.pushState = async function (state) {


                // @ts-ignore
                if (typeof history.onpushstate == "function") {
                    // @ts-ignore
                    history.onpushstate({ state: state });
                }

                setTimeout(() => log(), 500);

                // ... whatever else you want to do
                // maybe call onhashchange e.handler
                // @ts-ignore
                return pushState.apply(history, arguments);
            };
        })(window.history);

        window.addEventListener("beforeunload", () => {
            log({ type: 2 })
        })
    }

    identify(options: { userId: string }) {
        this.uid = options.userId;
    }

    config(settings: Partial<Props>) {
        if (settings.projectId) {
            this.pid = settings.projectId
        }

        if (settings.endpoint) {
            this.endpoint = settings.endpoint
        }

        this.log()
    }

    addEvent(eventName: string, meta?: any) {
        return this.r(`events/${this.pid}`, {
            method: "post",
            data: {
                eventName,
                meta
            }
        })
    }
}