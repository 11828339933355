import st from "../styles/shared";
import DatamapView from "../components/DataMap";

import { useState } from "react";
import { createClassStr } from "easier-class";
import { renderCountryItem } from "./shared/renderCountryItem";
import { ProjectOverview } from "../modules/shared/types/Project";
import { TabControlView } from "../components/Box/TabControlView";
import { SectionAnalyticComponent } from "../components/SectionAnalyticComponent";

type Props = {
  pid?: string;
  data?: ProjectOverview;
};

export default function AnalyticLocationComponent(props: Props) {
  const { pid, data } = props;
  const [tab, setTab] = useState(0);

  console.log(tab);

  return (
    <div className={createClassStr(st.box, "lg:col-span-2")}>
      <div className="flex flex-row item-center justify-between p-4">
        <label className="text-lg font-semibold">Users by Country</label>
        <TabControlView tabs={["Country", "City"]} onChange={setTab} />
      </div>
      <div className="flex flex-col md:flex-row gap-4 border-t">
        <DatamapView
          className="flex-1 mt-4 md:mt-8"
          key={pid}
          projectId={pid}
          items={data?.countries}
        />
        <SectionAnalyticComponent
          className="flex-1 border-l"
          activeTab={tab}
          sections={[
            {
              labelText: "Country",
              valueText: "Total",
              data: data?.countries,
              renderItem: renderCountryItem,
            },
            {
              labelText: "City",
              valueText: "Total",
              data: data?.cities,
            },
          ]}
        />
      </div>
    </div>
  );
}
