import { createClassStr } from "easier-class";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/icons/Spinner";
import AppContext from "../layouts/Context";

export default function Splash() {

  const ctx = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (ctx.user === null) {
      navigate("/login")
    }
  })

  return (
    <div className={createClassStr("flex items-center justify-center gap-3")}>
      <Spinner />
      <span>Loading...</span>
    </div>
  );
}
