
export type RequestOptions = {
    data?: any,
    method?: "post" | "get" | "delete" | "patch",
    query?: any,
    headers?: HeadersInit
}

export type RequestResult<T> = {
    status: "ok" | "error", message?: string, data: T, [name: string]: any
}

export function createRequest<T = any>(configs: { endpoint: string }) {
    const { endpoint } = configs;

    return function (path: string, options?: RequestOptions): Promise<RequestResult<T>> {

        let { data, method = "get", query, headers } = options || {};

        const queryStr = formatQueryStr(query);
        const url = `${endpoint}/${path}${queryStr}`;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method,
                headers: {
                    "content-type": "application/json",
                    "accept": "application/json",
                    ...headers
                },
                body: data ? JSON.stringify(data) : undefined
            })
                .then(rs => rs.json())
                .then(resolve)
                .catch(reject)
        })
    }
}

function formatQueryStr(query?: any) {
    if (!query) return "";
    let keys = Object.keys(query);
    if (!keys.length) return "";

    return `?` + keys.map(k => `${k}=${query[k]}`).join("&")
}