import { createClassStr } from "easier-class";

type Props = {
  className?: string;
  size?: "sm" | "md" | "lg";
  width?: number;
};
export function Skeleton(props: Props) {
  const { className, size = "md" } = props;
  const sizeClass = sizes[size];

  return (
    <div className={createClassStr(sizeClass, className, "animate-pluse")} />
  );
}

const sizes = {
  sm: `h-12`,
  md: `h-16`,
  lg: `h-20`,
};
