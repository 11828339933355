import React from "react";

import { createClassStr } from "easier-class";

type State = {
  visible?: boolean;
  label?: string;
  value?: any;
};


export class ChartLabel extends React.Component {
  state: State = {};

  show = (state: Partial<State>) => {
    if (!this.state.visible) {
      state.visible = true;
    }
    this.setState(state);
  };

  hide = () => {
    this.setState({ visible: false });
  };

  render(): React.ReactNode {
    const { visible, label = "", value = "" } = this.state;

    if (!visible) {
      return null;
    }

    return (
      <div
        className={createClassStr(
          "flex flex-row gap-2 border rounded bg-white pointer-events-none shadow-sm",
          "py-2 px-4 text-xs absolute left-4 top-4"
        )}
      >
        <label className="font-semibold">{label}:</label>
        <span>{value}</span>
      </div>
    );
  }
}
