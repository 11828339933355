import st from "../styles/shared";

import { ArrowDown3 } from "iconsax-react";
import { Fragment, useState } from "react";
import { createClassStr } from "easier-class";

const activeClass = "bg-slate-100 text-blue-600 font-semibold";
const itemClass = "duration-200 flex flex-row items-center gap-3 py-2 px-4 cursor-pointer";

type DropdownOption = {
  label: any;
  value: any;
};
type DropdownProps = {
  label?: any;
  name?: string;
  defaultValue?: DropdownOption;
  onChange?: (value: any, name: string) => any;
  options: DropdownOption[];
  Footer?: any;
  labelClassName?: string;
  renderItem?: (options: {
    item: DropdownOption;
    index: number;
    isActive: boolean;
    className: string;
    handleSelect: Function;
  }) => any;
};

type State = {
  selected?: DropdownOption;
  visible: boolean;
};

export default function Dropdown(props: DropdownProps) {
  const [state, setState] = useState<State>({
    visible: false,
    selected: props.defaultValue,
  });
  const { renderItem, name, label, options, Footer, labelClassName } = props;
  const { visible, selected } = state;

  function toggleVisible() {
    setState({ ...state, visible: !state.visible });
  }

  function _renderItem(item: DropdownOption, i: number) {
    const key = `${name || ""}-option-${item.value}`;
    const isActive = selected?.value === item.value;
    const className = createClassStr(
      itemClass,
      i !== 0 && "border-t",
      isActive ? activeClass : "hover:bg-slate-100/40"
    );

    function handleSelect() {
      setState({ visible: false, selected: item });
      props.onChange && props.onChange(item.value, name || "");
    }

    if (renderItem) {
      return (
        <Fragment key={key}>
          {renderItem({ item, index: i, isActive, className, handleSelect })}
        </Fragment>
      );
    }

    return (
      <div key={key} className={className} onClick={handleSelect}>
        {item.label}
      </div>
    );
  }

  return (
    <div className="relative select-none">
      <label
        onClick={toggleVisible}
        className={createClassStr(
          "flex items-center justify-between gap-2 text-sm font-semibold cursor-pointer p-2 pl-4 pr-4 border",
          visible ? "text-slate-900 rounded-t-md" : "rounded-md",
          "shadow-sm",
          st.borderColor,
          st.bg,
          labelClassName
        )}
      >
        {label || selected?.label || "(select value)"}
        <ArrowDown3
          className={createClassStr("duration-200", visible ? "rotate-90" : "")}
          variant={visible ? "Bold" : "Outline"}
          size={14}
        />
      </label>
      <div
        className={createClassStr(
          visible ? "" : "hidden",
          "absolute top-[37px] w-full flex flex-col z-10 shadow-md shadow-slate-200 text-sm",
          "border rounded-b-lg overflow-hidden",
          st.bg,
          st.borderColor
        )}
      >
        <div className={createClassStr("flex-1 flex flex-col max-h-[400px]")}>
          <div className="scrollbar overflow-y-auto">
            {(options || []).map(_renderItem)}
          </div>
          {Footer ? (
            <div className="p-2 flex flex-row justify-center border-t">
              {Footer}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
