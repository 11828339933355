import { useContext } from "react";
import { Link } from "react-router-dom";
import { Crown1, User } from "iconsax-react";
import { createClassStr } from "easier-class";

import AppContext from "../Context";
import st from "../../styles/shared";

const containerClassName = "flex flex-col p-4 gap-3";
export function MenuFooter() {
  const { user } = useContext(AppContext);

  if (!user) {
    return (
      <div style={{ minHeight: `65px` }} className={containerClassName}>
        <Link
          className={createClassStr(st.btnDefault, "text-center flex-1")}
          to="/login"
        >
          Login
        </Link>
        <div className="flex flex-row gap-2 items-center">
          <Crown1
            className="text-orange-400 inline-block mr-2 -translate-y-0.5"
            size={32}
          />
          <p className="flex-1">Start to sync notes and unlock awesome features</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`flex flex-col`}>
      <Link to="/account/settings" className="flex flex-row items-center gap-2">
        <span className={"bg-slate-200 p-2 rounded-full"}>
          <User size={16} />
        </span>
        <span>{user.email}</span>
      </Link>
    </div>
  );
}
