import { createClassStr } from "easier-class";
import { Refresh } from "iconsax-react";
import { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { auth } from "../../includes/conn";

import st from "../../styles/shared";

export function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    if (email) {
      setLoading(true);
      const { error } = await auth.resetPasswordForEmail(email);

      if (error) {
        toast.error(error.message);
      } else {
        toast.success(
          "We've sent an email with instructions to reset your password"
        );
      }

      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-full w-full items-center justify-center">
      <h1 className="text-2xl font-bold mb-6 w-72">Reset password</h1>
      <div className={createClassStr("border rounded-md p-6 relative", st.bg)}>
        <form className="flex flex-col gap-2 w-64" onSubmit={handleLogin}>
          <div className="flex flex-col border rounded p-2 px-3 gap-2 mb-2">
            <label className="text-xs font-bold" htmlFor="email">
              Your Email
            </label>
            <input
              id="email"
              className="bg-transparent outline-none"
              type="email"
              placeholder="i.e john@citizen.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button className={st.btnPrimary}>
            {loading ? (
              <Refresh
                size={16}
                className="animate-spin absolute right-4 top-3.5"
              />
            ) : null}
            Reset password
          </button>
        </form>

        <p className="mt-4 text-xs">
          Go back to{" "}
          <Link className="font-bold" to="/login">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
}
