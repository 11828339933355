import { useContext, useEffect } from "react";
import { createClassStr } from "easier-class";
import { Link, useNavigate } from "react-router-dom";
import { AddCircle } from "iconsax-react";

import { SiteIcon } from "../components/SiteIcon";
import { ProjectItem } from "../modules/shared/types/Project";

import st from "../styles/shared";
import AppContext from "../layouts/Context";
import Spinner from "../components/icons/Spinner";

export default function Home() {
  const { user, projects } = useContext(AppContext);
  const navigate = useNavigate();
  const containerClass = createClassStr("duration-200 flex flex-row p-4 h-24");

  useEffect(() => {
    if (user === null) {
      navigate("/login");
      return;
    }
  });

  function renderItem(item: ProjectItem) {
    return (
      <Link
        className={createClassStr(
          containerClass,
          st.box,
          "hover:border-slate-700/40",
          "flex flex-row items-center gap-4"
        )}
        key={`project-home-${item.id}`}
        to={`/projects/${item.id}`}
      >
        <SiteIcon domain={item.domain} name={item.name} size={40} />
        <div className="flex flex-col">
          <span className="text-lg font-bold">{item.name}</span>
          <span className="text-slate-400">{item.domain}</span>
        </div>
      </Link>
    );
  }

  return (
    <div className={createClassStr("flex flex-col h-screen w-full md:w-[960px]")}>
      <h1 className="font-extrabold text-4xl my-12">Your projects</h1>

      {projects ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <Link
            className={createClassStr(
              containerClass,
              st.borderColor,
              "flex-row items-center justify-center gap-4 border-2 border-dashed rounded-md text-slate-500 hover:text-slate-800 hover:bg-slate-200"
            )}
            to={`/create-project`}
          >
            <AddCircle size={21} />
            <span className="text-sm">New project</span>
          </Link>
          {projects.map(renderItem)}
        </div>
      ) : (
        <div className="flex flex-row gap-2">
          <Spinner size={4} />
          <span>Loading...</span>
        </div>
      )}
    </div>
  );
}
