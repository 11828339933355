import st from "../styles/shared";
import AppContext from "../layouts/Context";
import Dropdown from "../components/Dropdown";
import ProjectAPI from "../includes/api/Projects";
import AnalyticLocationComponent from "./AnalyticLocationComponent";

import { toast } from "react-toastify";
import { Link, useMatch } from "react-router-dom";
import { createClassStr } from "easier-class";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import {
  SectionAnalyticComponent,
  SectionRenderItemProps,
} from "../components/SectionAnalyticComponent";

import { SiteIcon } from "../components/SiteIcon";
import { Skeleton } from "../components/Skeleton";
import { ProjectOverview } from "../modules/shared/types/Project";
import { SectionChartComponent } from "../components/SectionChartComponent";
import { ArrowRight } from "iconsax-react";

export function AnalyticComponent() {
  const { projects } = useContext(AppContext);
  const [data, setData] = useState<ProjectOverview>();
  const match = useMatch("/projects/:pid/:screen");
  const pid = match?.params.pid;
  const project = pid
    ? projects?.find((item) => item.id === match.params.pid)
    : undefined;
  const currentIdRef = useRef("");
  const [period, setPeriod] = useState(7);

  const fetchData = useCallback(
    function (dateCount?: number) {
      if (pid) {
        let dc = dateCount || period;
        let end = Date.now();
        let startDate = new Date();

        startDate.setHours(0, 0, 0);
        startDate.setDate(startDate.getDate() - dc);

        ProjectAPI.getOverview({
          id: pid,
          start: startDate.getTime(),
          end,
          group: dc === 1 ? "hour" : "day",
        })
          .then((rs) => {
            setData(rs.data);
            typeof dateCount !== "undefined" && setPeriod(dateCount);
          })
          .catch((err) => {
            toast.error(err.toString());
          });
      }
    },
    [period, pid]
  );

  useEffect(() => {
    if (pid && currentIdRef.current !== pid) {
      currentIdRef.current = pid;
      setData(undefined);
      fetchData();
    }
  }, [pid, data, currentIdRef, fetchData]);

  function handlePeriodChange(value: number) {
    fetchData(value);
  }

  function renderSourceItem(options: SectionRenderItemProps) {
    const { label, value } = options.item;
    return (
      <div
        key={`referer-${label}`}
        className={createClassStr(
          "flex flex-row justify-between px-4 py-2 hover:bg-slate-100/40",
          options.index > 0 && "border-t",
          st.borderColorLtr
        )}
      >
        <span className="inline-flex flex-row gap-2 items-center">
          {label !== "(unknown)" ? (
            <>
              <SiteIcon domain={label} name={label} size={18} />
              <a target="_blank" rel="noreferrer" href={`https://${label}`}>
                {label.replace("www.", "")}
              </a>
            </>
          ) : (
            label
          )}
        </span>
        <span>{value}</span>
      </div>
    );
  }

  const isEmpty =
    data && data.views.reduce((total, value) => value.value + total, 0) === 0;
  console.log(isEmpty);
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between md:items-end gap-4">
        <div>
          <h1 className="font-black text-4xl">Overview</h1>
          <span className="text-slate-500 font-semibold mt-4 text-sm">
            {project ? project.domain : <Skeleton width={40} />}
          </span>
        </div>
        <Dropdown
          defaultValue={dateRangeOptions[1]}
          labelClassName="min-w-[150px]"
          options={dateRangeOptions}
          onChange={handlePeriodChange}
        />
      </div>
      {isEmpty ? (
        <Link
          className={createClassStr(
            "text-sm flex flex-col md:flex-row justify-between p-4 rounded-md bg-white gap-4",
            "border-l-8 border-green-600/80 text-green-800 border mt-4 -mb-4 items-center"
          )}
          to={`/projects/${pid}/settings#setup`}
        >
          <span>
            No data received. If you haven't integrate Gum Analytics to your site, click to learn how
          </span>
          <ArrowRight />
        </Link>
      ) : null}
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-8 mb-4 gap-4">
        <SectionChartComponent
          title="Unique visitors"
          sections={[
            {
              labelText: "visitor",
              valueText: "Total",
              data: data?.users,
              labelType: period === 1 ? "hour" : "day",
            },
          ]}
        />

        <SectionChartComponent
          title="Pageviews"
          sections={[
            {
              labelText: "view",
              valueText: "Total",
              data: data?.views,
              labelType: period === 1 ? "hour" : "day",
            },
          ]}
        />

        <AnalyticLocationComponent data={data} pid={pid} />

        <SectionAnalyticComponent
          title="Pages"
          sections={[
            {
              labelText: "Page",
              labelClassName: "",
              valueText: "Total",
              data: data?.pages,
            },
          ]}
        />

        <SectionAnalyticComponent
          title="Referers"
          sections={[
            {
              labelText: "Source",
              valueText: "Total",
              data: data?.sources,
              renderItem: renderSourceItem,
            },
          ]}
        />

        <SectionAnalyticComponent
          title="Technology"
          sections={[
            {
              labelText: "Device",
              valueText: "Total",
              data: data?.devices,
            },
            {
              labelText: "Browser",
              valueText: "Total",
              data: data?.browsers,
            },
          ]}
        />

        <SectionAnalyticComponent
          title="Events"
          sections={[
            {
              labelText: "Name",
              valueText: "Total",
              data: data?.events,
            },
          ]}
        />
      </div>
    </>
  );
}

const dateRangeOptions = [
  {
    label: "Last 24 hours",
    value: 1,
  },
  {
    label: "Last 7 days",
    value: 7,
  },
  {
    label: "Last 30 days",
    value: 30,
  },
];
