import { createClassStr } from "easier-class";
import { useState } from "react";

type Props = {
  defaultTab?: number;
  tabs: string[];
  onChange: (tabIndex: number) => void;
};

export function TabControlView(props: Props) {
  const { defaultTab, tabs } = props;
  const [tab, setTab] = useState(defaultTab || 0);

  if (tabs.length < 2) {
    return null;
  }

  function renderBtnLabel(labelText: string, i: number) {
    function handleClick() {
      setTab(i);
      props.onChange(i)
    }

    return (
      <button
        key={`tab-${labelText}`}
        className={createClassStr(
          "border rounded px-2 py-1 text-xs font-semibold",
          i === tab
            ? "border-slate-300 shadow-sm shadow-slate-100 text-slate-800"
            : "border-transparent text-slate-400"
        )}
        onClick={handleClick}
      >
        {labelText}
      </button>
    );
  }

  return <div className="flex flex-row gap-1">{tabs.map(renderBtnLabel)}</div>;
}
