// import { isDev } from "../configs";
// import { UserPublicInfo } from "./entity/User";
// let user: UserPublicInfo | undefined | null;

const endpoint =
    // isDev ? `http://localhost:8080` :
        `https://lsn.gumanalytics.com`;


export async function r<T = any>(path: string, options?: {
    data?: any,
    method?: "post" | "get" | "delete" | "patch",
    useBearer?: boolean,
    query?: any
}): Promise<{ status: "ok" | "error", message?: string, data: T, [name: string]: any }> {

    let { data, method = "get", useBearer, query } = options || {};

    const queryStr = formatQueryStr(query);
    const url = `${endpoint}/${path}${queryStr}`;

    let headers = {
        "Content-Type": "application/json",
        Accept: "application/json"
    }

    if (useBearer) {
        // if (!user) {
        //     let [, userInfo] = await goPromise(fc.Profile.getProfile());
        //     if (userInfo) {
        //         user = userInfo;
        //     }
        // }

        // headers["Authorization"] = user ? `Bearer ${user.login_token}` : ``;
        // data = { ...data, token: user?.login_token };
    }

    return new Promise((resolve, reject) => {
        fetch(url, {
            method,
            headers,
            body: data ? JSON.stringify(data) : undefined
        })
            .then(rs => rs.json())
            .then(resolve)
            .catch(reject)
    })
}

function formatQueryStr(query?: any) {
    if (!query) return "";
    let keys = Object.keys(query);
    if (!keys.length) return "";

    return `?` + keys.map(k => `${k}=${query[k]}`).join("&")
}