import AppLayout from "./layouts/app";
import AccountLayout from "./layouts/Account";
import ProjectNew from "./routes/projects/New";

import { AppContextContainer } from "./layouts/Context";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import { Login } from "./routes/account/Login";
import { Register } from "./routes/account/Register";

import { ResetPassword } from "./routes/account/ResetPassword";
import { AnalyticComponent } from "./routes/AnalyticComponent";
import { VisitorComponent } from "./routes/VisitorComponent";
import { SettingComponent } from "./routes/SettingComponent";
import Splash from "./routes/Splash";
import Home from "./routes/Home";
import Logout from "./routes/account/Logout";
import { AccountSettingComponent } from "./routes/account/Settings";

function GoHere() {
  return <Navigate to={`${window.location.pathname}/overview`} />;
}

const router = createBrowserRouter([
  {
    path: "/reset-password",
    element: (
      <AccountLayout>
        <ResetPassword />
      </AccountLayout>
    ),
  },
  {
    path: "/login",
    element: (
      <AccountLayout>
        <Login />
      </AccountLayout>
    ),
  },
  {
    path: "/logout",
    element: (
      <AccountLayout>
        <Logout />
      </AccountLayout>
    ),
  },
  {
    path: "/register",
    element: (
      <AccountLayout>
        <Register />
      </AccountLayout>
    ),
  },
  {
    path: "/",
    element: (
      <AccountLayout>
        <Splash />
      </AccountLayout>
    ),
  },
  {
    path: "/projects",
    element: (
      <AccountLayout navigateLoggedInUser={false}>
        <Home />
      </AccountLayout>
    ),
  },
  {
    path: "/create-project",
    element: (
      <AccountLayout navigateLoggedInUser={false}>
        <ProjectNew />
      </AccountLayout>
    ),
  },
  {
    path: "/projects/:pid",
    element: <GoHere />,
  },
  {
    path: "/projects/:pid/visitors",
    element: (
      <AppLayout>
        <VisitorComponent />
      </AppLayout>
    ),
  },
  {
    path: "/projects/:pid/overview",
    element: (
      <AppLayout>
        <AnalyticComponent />
      </AppLayout>
    ),
  },
  {
    path: "/projects/:pid/settings",
    element: (
      <AppLayout>
        <SettingComponent />
      </AppLayout>
    ),
  },
  {
    path: "/account/settings",
    element: (
      <AppLayout>
        <AccountSettingComponent />
      </AppLayout>
    ),
  },
]);

export default function App() {
  return (
    <AppContextContainer>
      <RouterProvider router={router} />
    </AppContextContainer>
  );
}
