import st from "../styles/shared";

import { useState } from "react";
import { createClassStr } from "easier-class";
import { ArrowRotateRight, ClipboardText } from "iconsax-react";
import { OverviewItem } from "../modules/shared/types/Project";
import { Chart } from "./chart/Chart";

type SectionProps = {
  labelText: string;
  valueText: string;
  labelType?: "day" | "hour",
  data?: OverviewItem[];
  renderItem?: (options: { item: OverviewItem; index: number }) => any;
};

type Props = {
  title: string;
  sections: SectionProps[];
};

export function SectionChartComponent(props: Props) {
  const { title, sections } = props;
  const [activeTab, setTab] = useState(0);
  const tab = sections[activeTab]
  let data = (tab.data || [])
    .sort((a, b) => a.label.localeCompare(b.label));

  function renderBtnLabel(item: SectionProps, i: number) {
    const { labelText } = item;

    function handleClick() {
      setTab(i);
    }

    return (
      <button
        key={`${title}-tab-${labelText}`}
        className={createClassStr(
          "border rounded px-2 py-1 text-xs font-semibold",
          i === activeTab
            ? "border-slate-300 shadow-sm shadow-slate-100 text-slate-800"
            : "border-transparent text-slate-400"
        )}
        onClick={handleClick}
      >
        {labelText}
      </button>
    );
  }

  return (
    <div className={createClassStr(st.box, "col-span-1")}>
      <div className="flex flex-row item-center justify-between p-4">
        <label className="text-lg font-semibold">{title}</label>
        {sections.length > 1 ? (
          <div className="flex flex-row gap-1">
            {sections.map(renderBtnLabel)}
          </div>
        ) : null}
        <span className="text-3xl font-black mr-4 text-slate-500">
          {data.reduce((p, c) => c.value + p, 0)}
        </span>
      </div>
      {data ? (
        data.length ? (
          <div className="text-slate-700 border-t">
            <Chart labelType={tab.labelType} chartLabel={tab.labelText} name={title} className="p-4" data={data} />
          </div>
        ) : (
          <div className="border-t px-4 py-12 flex flex-col items-center justify-center gap-6 text-slate-400">
            <span className="border-2 rounded-full p-4 border-slate-200">
              <ClipboardText size={42} />
            </span>
            <span className="text-base">No data received</span>
          </div>
        )
      ) : (
        <div className="border-t px-4 py-12 flex flex-col items-center justify-center gap-6 text-slate-400">
          <span className="rounded-full p-4">
            <ArrowRotateRight className="animate-spin" size={42} />
          </span>
          <span className="text-base">Loading</span>
        </div>
      )}
    </div>
  );
}
