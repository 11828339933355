import { createClassStr } from "easier-class";
import { createRef, useEffect, useState } from "react";
import { formatDateStr } from "../../includes/utils/date";
import { OverviewItem } from "../../modules/shared/types/Project";
import { ChartLabel } from "./ChartLabel";

type Props = {
  className?: string;
  data: OverviewItem[];
  name: string;
  chartLabel: string;
  animationDelay?: number;
  maxLabels?: number;
  labelType?: "day" | "hour";
};

export function Chart(props: Props) {
  const [animated, setAnimated] = useState(false);
  const {
    data,
    className,
    name,
    chartLabel,
    animationDelay = 300,
    labelType = "day",
  } = props;

  let maxLabels = props.maxLabels || labelType === "hour" ? 8 : 10;

  const labelRef = createRef<ChartLabel>();
  const max = Math.max(...data.map((item) => item.value));

  useEffect(() => {
    if (!animated) {
      setTimeout(() => setAnimated(true), animationDelay);
    }
  });

  const barClassName = `duration-200 ease-in-out flex-1 bg-[#1a73e8] ${
    data.length < 10 ? "rounded-t-[5px]" : "rounded-t-[2px]"
  }`;

  function handleMouseLeave(ev: React.MouseEvent<HTMLSpanElement>) {
    labelRef.current && labelRef.current.hide();
  }

  function handleMouseLeaveItem(ev: React.MouseEvent<HTMLSpanElement>) {
    ev.currentTarget.classList.remove("bg-slate-200");
    ev.currentTarget.classList.add("group-hover:opacity-60");
  }

  function renderBarItem(item: OverviewItem, i: number) {
    const height = (item.value * 100) / max;

    function handleMouseOver(ev: React.MouseEvent<HTMLSpanElement>) {
      ev.currentTarget.classList.remove("group-hover:opacity-60");
      ev.currentTarget.classList.add("bg-slate-200");

      labelRef.current &&
        labelRef.current.show({
          label:
            labelType === "hour"
              ? new Date(item.label).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })
              : formatDateStr(new Date(item.label)),
          value: `${item.value} ${chartLabel}${item.value !== 1 ? "s" : ""}`,
        });
    }

    return (
      <span
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseLeaveItem}
        className="duration-200 flex flex-1 bg-blue h-full items-end group-hover:opacity-60"
        key={`chart-${name}-${i}-key-${item.value}`}
      >
        <span
          style={{ height: `${animated ? height : 0}%` }}
          className={barClassName}
        />
      </span>
    );
  }

  let labelCounter = Math.round(data.length / maxLabels);
  let marginCounter = Math.round(labelCounter / 2);

  function renderLabelItem(item: OverviewItem, i: number) {
    let date = new Date(item.label);

    if (data.length > maxLabels && i % labelCounter !== marginCounter) {
      return null;
    }

    return (
      <span
        key={`chart-${name}-${i}-label-${item.label}`}
        className="flex-1 text-center font-semibold text-xs mt-1 text-slate-600"
      >
        {labelType === "day"
          ? date.getDate()
          : date.toLocaleString("en-US", { hour: "numeric", hour12: true })}
      </span>
    );
  }

  return (
    <div className={createClassStr(className, "relative group")}>
      <div
        onMouseLeave={handleMouseLeave}
        className={`flex flex-row h-32 ${
          data.length < 10 ? "gap-1" : "gap-[2px]"
        } -mb-[1px]`}
      >
        {data.map(renderBarItem)}
      </div>
      <div className="flex flex-row border-t">{data.map(renderLabelItem)}</div>
      <ChartLabel ref={labelRef} />
    </div>
  );
}
