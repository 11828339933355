import { Global } from "iconsax-react";
import { useState } from "react";

type SiteIconProps = {
  domain: string;
  name: string;
  size?: number;
};

export function SiteIcon(props: SiteIconProps) {
  const { domain, name, size = 40 } = props;
  const [loadFailed, setLoadFail] = useState(false);

  if (loadFailed) {
    return (
      <span
        style={{ height: size, width: size }}
        className="flex items-center justify-center rounded-full bg-slate-200 text-slate-800 font-bold"
      >
        <Global size={size/1.6} />
      </span>
    );
  }

  return (
    <img
      src={`https://${domain}/favicon.ico`}
      alt={name}
      height={size}
      width={size}
      onError={() => setLoadFail(true)}
    />
  );
}
