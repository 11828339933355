import { createClassStr } from "easier-class";
import { useState } from "react";
import { toast } from "react-toastify";
import ProjectAPI from "../../includes/api/Projects";
import { preventDefault } from "../../includes/utils/htmlEvents";
import { ProjectItem } from "../../modules/shared/types/Project";
import st from "../../styles/shared";

type Props = {
  project: ProjectItem;
};
export function SectionUpdate(props: Props) {
  const { project } = props;
  const [input, setInput] = useState(project.name);

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    preventDefault(ev);

    const form = ev.currentTarget;
    const name = form.itemName.value || "";

    if (name !== project.name) {
      const wait = toast.loading("Updating...");

      ProjectAPI.update(project.id, { name })
        .then((rs) => {
          toast.success(`Project removed`);
        })
        .catch((err) => {
          toast.error(err.toString());
        })
        .finally(() => {
          toast.dismiss(wait);
        });
    }
  }

  function handleInputChange(ev: React.ChangeEvent<HTMLInputElement>) {
    setInput(ev.currentTarget.value);
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={createClassStr(
        st.box,
        "flex flex-col flex-1"
      )}
    >
      <label className="text-lg font-semibold p-4">Basic info</label>
      <div className="flex flex-col items-start border-t py-4 px-6 gap-4">
        <div className="relative flex-1 text-slate-400">
          <input
            disabled
            className={createClassStr(
              st.input,
              "pl-24 w-full max-w-64 font-semibold"
            )}
            value={project.id}
          />
          <span className="absolute top-2.5 left-3 text-sm pointer-events-none">
            Project Id:
          </span>
        </div>
        <div className="relative flex-1 text-slate-400">
          <input
            disabled
            className={createClassStr(
              st.input,
              "pl-24 w-full max-w-64 font-semibold"
            )}
            value={project.domain}
          />
          <span className="absolute top-2.5 left-3 text-sm pointer-events-none">
            Domain:
          </span>
        </div>
        <div className="relative flex-1">
          <input
            onChange={handleInputChange}
            name="itemName"
            className={createClassStr(
              st.input,
              "pl-24 w-full max-w-64 font-semibold"
            )}
            defaultValue={project.name}
          />
          <span className="absolute top-2.5 left-3 text-sm text-slate-400 pointer-events-none">
            Name:
          </span>
        </div>
        <button disabled={input === project.name} className={st.btnDefault}>
          Update
        </button>
      </div>
    </form>
  );
}
