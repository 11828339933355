import { Google, Refresh } from "iconsax-react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Github } from "../../components/icons/Github";
import { Twitter } from "../../components/icons/Twitter";
import { auth } from "../../includes/conn";
import AppContext from "../../layouts/Context";

export function LoginWithProvider() {
  let [loading, setLoading] = useState(false);
  const ctx = useContext(AppContext);
  const navigate = useNavigate();

  function handleLogin(ev: React.MouseEvent<HTMLButtonElement>) {
    const provider = ev.currentTarget.dataset.provider as any;

    if (!provider) return;

    let fn = auth.signInWithOAuth({
      provider,
      options: {
        redirectTo: window.location.origin,
      },
    });

    setLoading(true);

    fn.then((rs: any) => {
      if (rs.user) {
        ctx.updateState({ user: rs.user });
        navigate("/");
      } else {
        toast.success("Redirecting...");
      }
    })
      .catch((err: any) => {
        toast.error(err.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="flex flex-col pb-4 mb-4 gap-3">
      {loading ? (
        <Refresh size={16} className="animate-spin absolute top-4 right-4" />
      ) : null}
      <button
        className="flex flex-row items-center justify-center bg-[#DB4437] gap-2 relative hover:opacity-80 duration-200 text-white rounded p-3 text-sm font-bold"
        aria-live="polite"
        onClick={handleLogin}
        data-provider="google"
        disabled={loading}
      >
        <Google variant="Bold" size={16} />
        Continue with Google
      </button>
      {/* <button
        className="flex flex-row items-center justify-center bg-[#1877f2] gap-2 relative hover:opacity-80 duration-200 text-white rounded p-3 text-sm font-bold"
        aria-live="polite"
        data-provider="facebook"
        onClick={handleLogin}
        disabled={loading}
      >
        <Facebook variant="Bold" size={16} />
        Continue with Facebook
      </button> */}
      <button
        className="flex flex-row items-center justify-center bg-[#1DA1F2] gap-2 relative hover:opacity-80 duration-200 text-white rounded p-3 text-sm font-bold"
        aria-live="polite"
        data-provider="twitter"
        onClick={handleLogin}
        disabled={loading}
      >
        <Twitter height={16} width={16} />
        Continue with Twitter
      </button>
      <button
        className="flex flex-row items-center justify-center bg-[#6e5494] gap-2 relative hover:opacity-80 duration-200 text-white rounded p-3 text-sm font-bold"
        aria-live="polite"
        data-provider="github"
        onClick={handleLogin}
        disabled={loading}
      >
        {/* <Github variant="Bold" size={16} /> */}
        <Github height={16} width={16} />
        Continue with Github
      </button>
    </div>
  );
}
