import { createClassStr } from "easier-class";
import st from "../styles/shared";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
};
export function Input(props: Props) {
  let { label, ...rest } = props;

  return (
    <div className="relative flex-1 text-slate-400">
      <input
        disabled
        className={createClassStr(
          st.input,
          "pl-24 w-full max-w-64 font-semibold"
        )}
        {...rest}
      />
      <span className="absolute top-2.5 left-3 text-sm pointer-events-none">
        {label}
      </span>
    </div>
  );
}
